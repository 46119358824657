/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('body');

export default function RecipientBank({ showModal, onHide }) {
  return (
    <Modal isOpen={showModal} onRequestClose={onHide}>
      <button type="button" className="btn-close" onClick={onHide}>
        <span aria-hidden="true">&times;</span>
      </button>

      <p>
        <strong style={{fontWeight: "bold"}}>Please see the guidance below to help provide the correct payment information:</strong>
      </p>

      <p>
        <strong style={{fontWeight: "bold"}}>SORT code</strong>: To receive GBP to a UK bank account
      </p>
      
      <p>
        <strong style={{fontWeight: "bold"}}>IBAN</strong>: If your account has an IBAN number, commonly used for sending EUR to European bank accounts
      </p>

      <p>
        <strong style={{fontWeight: "bold"}}>ACH routing</strong>: To receive USD to a US bank account
      </p>

      <p>
        <strong style={{fontWeight: "bold"}}>SWIFT code</strong>: SWIFT is used for international transfers for a number of countries and currencies. SWIFT takes the longest and fees may be added for recipients, so please give preference to other transfer options
      </p>

      <p>
        <strong style={{fontWeight: "bold"}}>BSB</strong>: To receive AUD to an Australian bank account
      </p>

      <p>
        <strong style={{fontWeight: "bold"}}>Canadian transfers</strong>: To receive CAD into a Canadian bank account
      </p>
    </Modal>
  );
}

RecipientBank.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
